@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800&display=swap');

body{
  font-family: 'Raleway', sans-serif;
  font-size:15px;
  font-weight:400;
  font-style:normal;
  line-height:1.74;
  position:relative;
  visibility:visible;
  overflow-x:hidden;
  -webkit-transition:all .25s cubic-bezier(.645,.045,.355,1);
  transition:all .25s cubic-bezier(.645,.045,.355,1);
  letter-spacing:0;
  color:#696969
}
h1,h2,h3,h4,h5,h6{
  font-weight:700;
  line-height:1.3;
  margin-top:0;
  color:#333
}
.h1,.h2,.h3,.h4,.h5,.h6{
  line-height:inherit;
  margin:0
}
p:last-child{
  margin-bottom:0
}
a,button{
  line-height:inherit;
  display:inline-block;
  cursor:pointer;
  text-decoration:none;
  color:inherit
}
a,button,img,input{
  -webkit-transition:all .25s cubic-bezier(.645,.045,.355,1);
  transition:all .25s cubic-bezier(.645,.045,.355,1)
}
:focus{
  outline:0!important
}
a:focus{
  text-decoration:none;
  color:inherit;
  outline:0
}
a:hover{
  text-decoration:none;
  color:#3f3a64;
}
button,input[type=submit]{
  cursor:pointer
}
ol, ul {
  padding-left: 1rem;
}

.pl-0{
  padding-left: 0!important;
} 
.pr-0{
  padding-right: 0!important;
}

/*--- section ---*/
section{
  position: relative;
  padding: 70px 0;
}
.section-title {
  position: relative;
  z-index: 99;
  margin-bottom: 30px;
}
.section-title .sub-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  display: block;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #8c89a2;
}
.section-title .title{
  font-size: 34px;
  font-weight: 500;
  line-height: 1.42;
  margin: 0;
  color: #3f3a64;
}
.section-title .title span {
  font-weight: 400;
  color: #20ad96;
}


/*---Header---*/
.header-section {
  z-index: 9999;
  min-height: 80px;
}
.header-section .header-inner {
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 80px;
  background-color: #fff;
}
.header-section.header-fluid .container {
  max-width: 100%;
}
.header-logo a {
  position: relative;
  max-width: 158px;
}
.header-logo a img {
  width: 100%;
  padding: 15px 0;
}
.header-logo a img.light-logo {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.site-main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.site-main-menu>ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.site-main-menu>ul>li {
  position: relative;
}
.site-main-menu>ul>li>a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.375;
  display: block;
  padding: 29px 17px;
  color: #3f3a64;
}
.site-main-menu>ul>li>a .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.site-main-menu>ul>li.has-children>a .menu-text::after {
  font-family: "FontAwesome";
  font-size: 8px;
  font-weight:500;
  margin-left: 10px;
  content: "";
}
.site-main-menu>ul>li .menu-toggle {
  display: none;
}
.site-main-menu .mega-menu, .site-main-menu .sub-menu {
  position: absolute;
  z-index: 9999;
  top: 100%;
  left: 0;
  visibility: hidden;
  width: 240px;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 18px 0;
  list-style: none;
  -webkit-transition: all .4s ease .2s;
  transition: all .4s ease .2s;
  opacity: 0;
  border-bottom: 4px solid #20ad96;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 29px rgb(0 0 0 / 5%);
  box-shadow: 0 2px 29px rgb(0 0 0 / 5%);
}
.site-main-menu .mega-menu li, .site-main-menu .sub-menu li {
  position: relative;
}
.site-main-menu>ul>li:hover .mega-menu, .site-main-menu>ul>li:hover>.sub-menu {
  visibility: visible;
  margin-top: 0;
  opacity: 1;
}
.site-main-menu .mega-menu li a, .site-main-menu .sub-menu li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.375;
  display: block;
  padding: 8px 30px;
  white-space: nowrap;
  color: #696969;
}
.site-main-menu .mega-menu li.active>a, .site-main-menu .mega-menu li:hover>a, .site-main-menu .sub-menu li.active>a, .site-main-menu .sub-menu li:hover>a {
  color: #111;
}
.site-main-menu .mega-menu li a .menu-text, .site-main-menu .sub-menu li a .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-search {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 250px;
  padding: 0 10px;
}
.header-search-toggle {
  line-height: 1;
  display: none;
  padding: 6px 10px;
  color: #111;
  border: none;
  background-color: transparent;
}
.header-search-toggle i {
  font-size: 18px;
  width: 18px;
}
.header-search-form form {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.header-search-form form input {
  min-height: 48px;
  padding-right: 50px;
}
input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url] {
  width: 100%;
  min-height: 56px;
  padding: 3px 20px;
  color: #696969;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  background-color: #f5f5f5;
}
.header-search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: #20ad96;
  border: none;
  background-color: transparent;
}
.header-search-form form button:hover {
  color: #fff;
  background-color: #20ad96;
}

.header-section.is-sticky .header-inner {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff!important;
  width: 100%;
  -webkit-animation: headerSlideDown .95s ease forwards;
  animation: headerSlideDown .95s ease forwards;
  -webkit-box-shadow: 0 8px 20px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 10%);
  transition: 1s;
}
.mobile-menu-open .site-main-mobile-menu-inner {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.site-main-mobile-menu-inner::before {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: rgba(63,58,100,.9);
}

.fullscreen-search-close .toggle, .header-mobile-menu-toggle .toggle, .mobile-menu-close .toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  border: none;
  background-color: transparent;
}
.fullscreen-search-close .toggle i, .header-mobile-menu-toggle .toggle i, .mobile-menu-close .toggle i {
  position: relative;
  display: block;
  overflow: hidden;
  width: 24px;
  height: 2px;
}
.fullscreen-search-close .toggle i.icon-bottom::before, .fullscreen-search-close .toggle i.icon-top::before, .header-mobile-menu-toggle .toggle i.icon-bottom::before, .header-mobile-menu-toggle .toggle i.icon-top::before, .mobile-menu-close .toggle i.icon-bottom::before, .mobile-menu-close .toggle i.icon-top::before {
  -webkit-transition: -webkit-transform .6s cubic-bezier(.165,.84,.44,1) .2s;
  transition: -webkit-transform .6s cubic-bezier(.165,.84,.44,1) .2s;
  transition: transform .6s cubic-bezier(.165,.84,.44,1) .2s;
  transition: transform .6s cubic-bezier(.165,.84,.44,1) .2s,-webkit-transform .6s cubic-bezier(.165,.84,.44,1) .2s;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  -webkit-transform-origin: right;
  transform-origin: right;
}
.fullscreen-search-close .toggle i.icon-bottom::after, .fullscreen-search-close .toggle i.icon-top::after, .header-mobile-menu-toggle .toggle i.icon-bottom::after, .header-mobile-menu-toggle .toggle i.icon-top::after, .mobile-menu-close .toggle i.icon-bottom::after, .mobile-menu-close .toggle i.icon-top::after {
  -webkit-transition: -webkit-transform .6s cubic-bezier(.165,.84,.44,1);
  transition: -webkit-transform .6s cubic-bezier(.165,.84,.44,1);
  transition: transform .6s cubic-bezier(.165,.84,.44,1);
  transition: transform .6s cubic-bezier(.165,.84,.44,1),-webkit-transform .6s cubic-bezier(.165,.84,.44,1);
  -webkit-transform: scaleX(0) translateZ(0);
  transform: scaleX(0) translateZ(0);
  -webkit-transform-origin: left;
  transform-origin: left;
}
.fullscreen-search-close .toggle i+i, .header-mobile-menu-toggle .toggle i+i, .mobile-menu-close .toggle i+i {
  margin-top: 6px;
}
.fullscreen-search-close .toggle i::after, .fullscreen-search-close .toggle i::before, .header-mobile-menu-toggle .toggle i::after, .header-mobile-menu-toggle .toggle i::before, .mobile-menu-close .toggle i::after, .mobile-menu-close .toggle i::before {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #333;
}
.fullscreen-search-close, .header-mobile-menu-toggle, .mobile-menu-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
}

.mobile-menu-open {
  overflow: hidden
}

.mobile-menu-open #page {
  -webkit-transform: translateX(-360px);
  transform: translateX(-360px)
}

.mobile-menu-open .site-main-mobile-menu {
  visibility: visible;
  opacity: 1
}

.mobile-menu-open .site-main-mobile-menu-inner {
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.mobile-menu-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 80px;
  padding: 0 15px 0 30px;
  background-color: #fff
}

.mobile-menu-logo a {
  width: 158px
}

.fullscreen-search-close, .mobile-menu-close {
  padding: 0
}

.fullscreen-search-close .toggle, .mobile-menu-close .toggle {
  position: relative;
  width: 40px;
  height: 40px
}

.fullscreen-search-close .toggle i, .mobile-menu-close .toggle i {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 29px;
  height: 2px;
  margin: 0 !important;
  -webkit-transform-origin: center;
  transform-origin: center
}

.fullscreen-search-close .toggle i.icon-top, .mobile-menu-close .toggle i.icon-top {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg)
}

.fullscreen-search-close .toggle i.icon-bottom, .mobile-menu-close .toggle i.icon-bottom {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg)
}

.fullscreen-search-close .toggle:hover i::after, .fullscreen-search-close .toggle:hover i::before, .mobile-menu-close .toggle:hover i::after, .mobile-menu-close .toggle:hover i::before {
  background-color: #111
}

.mobile-menu-content {
  overflow-y: auto;
  height: calc(100% - 80px);
  padding: 20px 30px 100px
}

.site-mobile-menu > ul {
  margin: 0;
  padding-left: 0;
  list-style: none
}

.site-mobile-menu > ul > li {
  position: relative !important
}

.site-mobile-menu > ul > li + li > a {
  border-top: 1px solid rgba(255, 255, 255, .15)
}

.site-mobile-menu > ul > li > a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  display: block;
  padding-top: 19px;
  padding-right: 0;
  padding-bottom: 19px;
  padding-left: 0;
  color: #fff;
  border-bottom: 1px solid transparent
}

.site-mobile-menu > ul > li .menu-toggle {
  position: absolute;
  top: 11px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  background-color: transparent
}

.site-mobile-menu > ul > li .menu-toggle i {
  font-size: 18px;
  line-height: 1;
  -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
  transition: all .25s cubic-bezier(.645, .045, .355, 1)
}

.site-mobile-menu > ul > li .menu-toggle:hover {
  background-color: rgba(255, 255, 255, .2)
}

.site-mobile-menu > ul > li.open > a {
  border-bottom-color: rgba(255, 255, 255, .15)
}

.site-mobile-menu > ul > li.open > .menu-toggle {
  background-color: rgba(255, 255, 255, .2)
}

.site-mobile-menu > ul > li.open > .menu-toggle i {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg)
}

.site-mobile-menu .mega-menu, .site-mobile-menu .sub-menu {
  display: none;
  margin: 14px 0;
  padding-left: 0;
  list-style: none
}

.site-mobile-menu .mega-menu li, .site-mobile-menu .sub-menu li {
  position: relative
}

.site-mobile-menu .mega-menu li > a, .site-mobile-menu .sub-menu li > a {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  padding-top: 10px;
  padding-right: 0;
  padding-bottom: 10px;
  padding-left: 0;
  color: rgba(255, 255, 255, .7)
}

.site-mobile-menu .mega-menu li > a:hover, .site-mobile-menu .sub-menu li > a:hover {
  color: #fff
}

.site-mobile-menu .mega-menu li .menu-toggle, .site-mobile-menu .sub-menu li .menu-toggle {
  top: 1px
}

.site-mobile-menu .mega-menu li.open > a, .site-mobile-menu .sub-menu li.open > a {
  color: #fff
}

.site-mobile-menu .mega-menu li.open > .menu-toggle, .site-mobile-menu .sub-menu li.open > .menu-toggle {
  background-color: rgba(255, 255, 255, .2)
}

.site-mobile-menu .mega-menu li.open > .menu-toggle i, .site-mobile-menu .sub-menu li.open > .menu-toggle i {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg)
}

.site-mobile-menu .mega-menu ul, .site-mobile-menu .sub-menu ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
  list-style: none
}

.site-mobile-menu .mega-menu ul li a .badge, .site-mobile-menu .sub-menu ul li a .badge {
  background-color: transparent;
  background-image: linear-gradient(45deg, #fe378c 0, #fe5b34 100%);
  display: inline-block;
  border-radius: 2px;
  padding: 4px 8px 3px;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .5px;
  margin: 0 0 0 7px
}

.site-mobile-menu .mega-menu ul li a .badge.primary, .site-mobile-menu .sub-menu ul li a .badge.primary {
  background-image: none;
  background-color: #20ad96 !important
}

.site-mobile-menu .mega-menu .sub-menu, .site-mobile-menu .sub-menu .sub-menu {
  padding-left: 15px
}


/*--- banner ---*/
.banner{
  position: relative;
  display: grid;
  align-items: center;
  background: url(./images/slider-01.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  height:818px
}
.banner-content{
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}
.banner-content h6{
  color:#777;
}
.banner-content h1{
  font-size: 50px;
  line-height: 60px;
  color:#3f3a64;
}
.banner-content h1 span{
  color:#20ad96;
}
.banner-content a{
  margin-top: 50px;
}


.btn {
  font-size: 14px;
  font-weight: 700;
  line-height: 50px;
  height: 52px;
  padding: 0 40px;
}
.btn-primary-two {
  border-color: #3a7e69;
  background-color: #3a7e69;
  color: #fff;
}
.btn-hover-secondary:hover, .btn-hover-secondary:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background-color: #3f3a64;
  color: #fff;
}

/*--- life style ---*/
.health-block{
  background: #e9f7f5;
    border-radius: 30px;
    overflow: hidden;
}
.health-icon-box {
  position: relative;
  display: block;
  padding: 32px;
  -webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
  transition: all .25s cubic-bezier(.645,.045,.355,1);
  z-index: 99;
}
.health-icon-box:hover {
  z-index: 1;
  -webkit-box-shadow: 0 0 40px rgb(51 51 51 / 10%);
  box-shadow: 0 0 40px rgb(51 51 51 / 10%);
}
.health-icon-box .icon {
  margin-bottom: 40px;
}
.health-icon-box .content .title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
  color: #3f3a64;
}
.health-icon-box .content .desc {
  margin-top: 15px;
}
.health-icon-box .content .desc p {
  font-size: 17px;
  line-height:24px;
  color: #696969;
}

.btn-off-white {
  border-color: #f6f2ed;
  background-color: #f6f2ed;
  color: #333;
}

/*--- services ---*/
.services{
  background-color: rgb(241, 244, 242);
}
.single-health-service {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.single-health-service .icon {
  margin-right: 34px;
}
.single-health-service .content .title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  color: #3f3a64;
  margin: 0;
}
.single-health-service .content .description {
  margin-top: 25px;
}
.single-health-service .content .description ul {
  padding-left: 20px;
  margin: 0;
}
.single-health-service .content .description ul li {
  font-size: 18px;
  line-height: 1.67;
}
.single-health-service .content .description ul li+li {
  margin-top: 10px;
}

/*--- blog ---*/
.blog-block{
  overflow: hidden;
  box-shadow: 0 0 10px #a1a1a1;
  border-radius: 10px;
  margin: 30px 0 0 0;
}
.blog-image{
  position: relative;
}

.blog-block .date{
  position: absolute;
  top: 10px;
  right: 10px;
  background: #20ad96;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  padding: 10px 0;
}
.blog-block .date h6{
  color: #fff;
  margin: 0;
  font-weight: 400;
}
.blog-block .date h5{
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 17px;
}

.blog-block .category{
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 7px 20px;
  background:#20ad96;
}
.blog-block .category h6{
  margin:0;
  font-weight: 500;
  color: #fff;
}

.blog-block .blog-title-decs{
  padding: 15px;
}
.blog-block .blog-title-decs h1{
  font-size: 20px;
}
.blog-block .blog-title-decs p{
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.blog-post-comment{
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.blog-post-comment .post-time,.blog-post-comment .post-comment{
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  color: #3a7e69;
}

/*--- footer ---*/
.footer-section {
  padding: 70px 0 50px;
  background-color: #18152c;
  overflow: hidden;
}
.footer-widget-title {
  font-size: 18px;
  margin-bottom: 17px;
  color:#fff;
}
.footer-widget-content {
  font-size: 14px;
  font-weight: 500;
}
.footer-widget-content p {
  margin-bottom: 11px;
}
.footer-widget-content p:last-child {
  margin-bottom: 0;
}

.footer-social-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-right: -25px;
  margin-bottom: -15px;
}
.footer-social-inline a {
  margin-right: 25px;
  margin-bottom: 15px;
  color: rgba(105,105,105,.5);
}
.footer-social-inline a i {
  font-size: 24px;
  line-height: 1;
  display: block;
}
.footer-social-inline a:hover {
  color: #20ad96;
}

.footer-widget-content ul.column-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.footer-widget-content ul {
  margin-right: -20px;
  margin-bottom: -11px;
  padding-left: 0;
  list-style: none;
}
.footer-widget-content ul.column-2 li {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
}
.footer-widget-content ul li {
  margin-bottom: 11px;
  padding-right: 20px;
}
.footer-widget-content ul li a:hover{
  color:#20ad96;
}

.copyright {
  font-size: 14px;
  text-align: center;
  color: #ababab;
}

/*--- about ---*/
.page-banner-section{
  background: url(../src/images/about-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}
.page-banner-title {
  max-width: 770px;
  margin: 0 auto;
  text-align: center;
}
.page-banner-title .title {
    font-size: 34px;
    line-height: 48px;
    color: #fff;
    margin-bottom: 0;
}

.about-section{
  background-color: rgb(245, 247, 250);
  margin-bottom: 100px;
}
.about-section .section-title .title{
  font-size: 55px;
  font-weight: 600;
  margin-bottom: 30px;
}
.about-section .section-title .title span {
  font-weight: 600;
}

.section-bottom-shape {
  position: absolute;
  z-index: 1;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 70px;
}
.section-bottom-shape svg {
  width: 100%;
  height: 100%;
}
.section-bottom-shape svg path {
  fill: #fff;
}

/*--- contact ---*/
.contact-title {
  margin-bottom: 40px;
}
.contact-title .sub-title {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}
.contact-title .title {
  font-size: 34px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 0;
}

.contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.contact-info .icon {
  margin-right: 20px;
  font-size: 32px;
  color: #20ad96;
}
.contact-info .icon i {
  vertical-align: top;
}
.contact-info .info .title {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 15px;
}
.contact-info .info .info-text {
  display: block;
  line-height: 32px;
}

input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url] {
  width: 100%;
  min-height: 45px;
  padding: 3px 20px;
  color: #696969;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  background-color: #f5f5f5;
}
textarea {
  width: 100%;
  min-height: 220px;
  padding: 10px 20px;
  color: #696969;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  background-color: #f5f5f5;
}

.max-mb-30 {
  margin-bottom: 30px!important;
}
.max-mb-50 {
  margin-bottom: 50px!important;
}
.btn-primary {
  border-color: #20ad96;
  background-color: #20ad96;
  color: #fff;
}
.btn-height-60 {
  height: 50px;
  line-height: 48px;
}


/*--- responsive ---*/
@media screen and (max-width:1366px){
  .banner{
    height: 650px;
  }
}
@media screen and (min-width:992px){
  .mobile-menu{
    display: none;
  }
  .menu-close{
    display: none!important;
  }
}
@media screen and (max-width:991px){
  .mobile-menu{
    position: fixed!important;
    background-color: rgba(63,58,100,.9);
    right:-300px;
    width: 300px;
    height: 100vh;
    z-index: 111;
    top: 0;
    transition: 1s;
  }
  .mobile-menu .site-main-menu{
    display: block;
  }
  .mobile-menu .site-main-menu>ul{
    display: block;
  }
  .mobile-menu .site-main-menu>ul li{
    display: block;
    border-bottom: 1px solid transparent;
  }
  .site-main-menu>ul>li>a {
    padding: 20px 15px;
    color: #fff;
    border-bottom: solid 1px #756f92;
}

.menu-close{
  text-align: right;
  font-size: 20px;
  margin: 0;
  padding: 5px;
}

  .show{
    right: 0!important;
    display: block!important;
  }

}
@media screen and (max-width:640px){
  section {
    padding: 30px 0;
  }
  .footer-section{
    padding: 30px 0;
  }
  .footer-widget{
    margin: 20px 0;
  }
  .btn {
    line-height: 25px;
    padding: 8px 20px;
    height: 45px;
  }
  .banner {
    height: 330px;
  }
  .banner-content h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .header-search-form{
    display: none;
  }
  .fullscreen-search-close, .header-mobile-menu-toggle, .mobile-menu-close {
    padding-right: 8px;
    padding-left: 8px;
  }
  .site-main-mobile-menu-inner {
    width: 300px;
  }
}